import moment from 'moment'
import { setHours, setMinutes, addMinutes } from 'date-fns'

import {
	CARD_BRANDS_TAG
} from '../enum/payment'

interface UserAddressesList {
	id: number
	address: string
	number: string | number
	cep: string
	neighborhood: string
	city: string
	state: string
	country: string
	complement: string
	reference: string
	category: string | number
	latitude: string | number
	longitude: string | number
}

interface UserInfo {
	id: number
	name: string
	fullName: string
	address: {
		main: string
		city: string
		neighborhood: string
		id: number
		latitude: string | number
		longitude: string | number
	}
}

interface OrderAnalysisRequest {
	EstabelecimentoId: number
	Produtos: any[]
	ModalidadeRetirada: number
	SubTotal: number
	AgendamentoSearaId?: number
	PagamentoAdHoc?: boolean
	ComandaId?: number
	CartaoId?: number
	TipoPagamento?: number
	Cpf?: boolean
	Cvv?: number | string
	ModalidadeRetiradaValor?: number
	EnderecoDeliveryId?: number
	BandeiraId?: number
	PagarNaEntrega?: boolean
	Troco?: number
	CupomId?: number
	Ip?: string
	FidelidadeModeloPontosId?: number
	LocalEntregaBalcao?: string
}

interface OrderAnalysisDataKonduto {
	id: string | number
	requestStatus: number
	orderStatus: number
	recommendation: 0
	score: any
}

interface OrderAnalysisData {
	orderId: number
	success: boolean
	konduto: OrderAnalysisDataKonduto
}

interface OrderCreateRequestKonduto {
	StatusChamada: number
	StatusPedido: number
	Recomendacao: string | number
	Score: null
	IdPedidoKonduto: string | number
}

interface OrderCreateRequest {
	EstabelecimentoId: number
	Produtos: any[]
	ModalidadeRetirada: number
	Konduto: OrderCreateRequestKonduto
	SubTotal: number
	AgendamentoSearaId?: number
	PagamentoAdHoc?: boolean
	ComandaId?: number
	CartaoId?: number
	TipoPagamento?: number
	Cpf?: boolean
	Cvv?: number | string
	ModalidadeRetiradaValor?: number
	EnderecoDeliveryId?: number
	BandeiraId?: number
	PagarNaEntrega?: boolean
	Troco?: number
	CupomId?: number
	Ip?: string
	FidelidadeModeloPontosId?: number
	LocalEntregaBalcao?: string
}

interface OrderCreateData {
	orderId: number
	success: boolean
	paymentStatus: boolean
	message: string
	cnpj: string
}

interface LoginData {
	id: number
	name: string
	success: boolean
	subscriptionStatus: number
	lastName: string
	email: string
	cpf: string
	phone: string
	birthDay: string
	loginType: string
	accessToken: string,
	message: string
}

interface LoginCreateRequest {
	Email: string
	Senha: string
}

interface ReceiptData {
	list: []
	requestNumber: number
	status: number
	paymentStatus: number
	orderDate: string
	summary: ReceiptDataSummary
	notification: ReceiptDataNotification
	deliveryAddress: string
	modalityId: number
	userName: string
	store: ReceiptDataStore
	success: boolean
	scheduling?: any
	isPaid: boolean,
	deliveryDiscount: number,
	cardBrand: string,
	cardNumber: string,
	cpf: string,
	earnedPoints: number
}

interface ReceiptDataNotification {
	status: number
	statuses: ReceiptDataNotificationStatuses[]
}

interface ReceiptDataStore {
	id: number
	name: string
	address: string
	logoUrl: string
	whatsapp: string
	phone: string
}

interface ReceiptDataNotificationStatuses {
	status: string
	time: string
	active: boolean
}

interface ReceiptDataSummary {
	total: number
	deliveryFee: number
	discount: number
	subtotal: number
}

interface CardsData {
	selectedCard: CardsDataSelectedCard
	availableOnlineCards: CardsDataCard[],
	availableOfflineCards: CardsDataCard[],
	success: boolean
}

interface CardsDataCard {
	id: number
	number: string
	brand: string
	brandName: string
	brandId: number
	cvv: number | string
	cvvIsRequired: boolean
	status: boolean
	paymentType: number
}

interface CardsDataSelectedCard {
	id: number
	number: string
	brand: string
	brandName: string
	brandId: number
	cvv: number | string
	cvvIsRequired: boolean
	status: boolean
	paymentType: number
}

interface AddCardResponse {
	success: boolean
	message: string
}

interface AddCardData {
	mundipaggCardId: string
	mundipaggUserId: string
	digits: number
	firstDigits: number
	brandId: number
	type: number
	cpf: number
	expMonth: number
	expYear: number
	name: string;
}

interface StoreDataProducts {
	id: number
	name: string
	description: string
	value: number
	image: string
	rewardImage: string
	additionals: StoreDataProductsAdditionals[]
}

interface StoreDataProductsAdditionals {
	name: string
	minimum: number
	maximum: number
	checkbox: boolean
	calcType: number
	items: StoreDataProductsAdditionalsItems[]
	groupId: number
}

interface StoreDataSearaAvailableDates {
	day: string
	date: string
	schedules: StoreDataSearaAvailableDatesSchedules[]
}

interface StoreDataLoyaltyPoints {
	loyaltyModelPointsId: number,
	modelPoints: number,
	points: number,
	minValue: number
}

interface StoreDataSearaAvailableDatesSchedules {
	id: number
	time: string
}

interface StoreDataProductsAdditionalsItems {
	name: string
	code: number
	value: number
	packPrice: number
	delivery: boolean
	counter: boolean
	description: string
	quantity: number
	groupId: number
	calcType: number
}

interface StoreDataCategories {
	id: number
	name: string
	description: string
	image: string
}

interface StoreDataImages {
	logo: string
	headerBackgroundImage: string
}

interface StoreData {
	aisle: boolean
	products: StoreDataProducts
	categories: StoreDataCategories
	allCategories: StoreDataCategories
	images: StoreDataImages
	id: number
	name: string
	address: string
	opened: boolean
	status: number
	logo: string
	deliveryTime: string
	adHoc: boolean
	deliveryMinimumValue: number
	pickupMinimumValue: number
	deliveryFee: number
	pickupFee: number
	selectedCategory: number
	cart: any
	searaAvailableDates: StoreDataSearaAvailableDates[],
	loyaltyPoints: StoreDataLoyaltyPoints
}

interface Card {
	id: number
	data: string
	brand: string
	brandId: number
	paymentType: number
	cvvRequired: boolean
	cardType: number
	active: boolean
	discount: boolean
	discountPercentage: number
	discountMaxValue: number
	blocked: boolean
}

interface deleteCardResponse {
	success: boolean,
	message: string
}

interface antiFraudeApi {
	success: boolean,
	message: string
}

interface antiFraudeConfirmation {
	ComandaId: number,
	Id: number
}

export const mapUserInfo = (data: any): UserInfo => {
	const fullAddress = data['Endereco'] && data['Endereco']['Endereco'] && data['Endereco']['Numero'] ? `${data['Endereco']['Endereco']}, ${data['Endereco']['Numero']}` : ''
	const incompleteAddress = data['Endereco'] && data['Endereco']['Endereco'] ? `${data['Endereco']['Endereco']}` : ''

	return {
		id: data['Id'],
		name: data['Nome'],
		fullName: `${data['Nome']} ${data['Sobrenome']}`,
		address: {
			main: fullAddress || incompleteAddress,
			neighborhood: (data['Endereco'] && `${data['Endereco']['Bairro']}`) || '',
			latitude: (data['Endereco'] && data['Endereco']['Latitude']) || '',
			longitude: (data['Endereco'] && data['Endereco']['Longitude']) || '',
			id: data['Endereco'] && data['Endereco']['Id'],
			city: (data['Endereco'] && data['Endereco']['Cidade']) || ''
		}
	}
}

export const mapUserOrderHistory = (data: any): UserInfo => {
	const orders = (data['Pedidos'] && data['Pedidos'].length && data['Pedidos'].map((mappedItem: any) => {
		return {
			id: mappedItem['Id'],
			storeName: mappedItem['Estabelecimento'] && mappedItem['Estabelecimento']['Nome'],
			date: mappedItem['Data'],
			status: mappedItem['Status'],
			total: mappedItem['Total'],
			qty: mappedItem['Quantidade']
		}
	})) || []

	return orders
}

export const mapUserAddressesList = (data: any): UserAddressesList[] | [] => {
	const addresses = (data['Enderecos'] && data['Enderecos'].length && data['Enderecos'].map((mappedItem: any) => {
		return {
			id: mappedItem['Id'],
			address: mappedItem['Endereco'],
			number: mappedItem['Numero'],
			cep: mappedItem['Cep'],
			neighborhood: mappedItem['Bairro'],
			city: mappedItem['Cidade'],
			state: mappedItem['Estado'],
			country: mappedItem['Pais'],
			complement: mappedItem['Complemento'],
			reference: mappedItem['Referencia'],
			category: mappedItem['TipoEndereco'],
			latitude: mappedItem['Latitude'],
			longitude: mappedItem['Longitude']
		}
	})) || []

	return addresses
}

function flatten(arr: any) {
	return arr && arr.reduce(function (flat: any, toFlatten: any) {
		return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
	}, [])
}

export const mapOrderCreateRequest = (data: any): OrderCreateRequest => {
	const products = data.products && data.products.length && data.products.map((productsMappedItem: any) => {
		const {
			quantity,
			selectedAdditionals = [],
			notes = '',
			id,
			value,
		} = productsMappedItem

		const additionals: any = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((additionalsMappedItem: any) => {
			return additionalsMappedItem.items && additionalsMappedItem.items.map((additionalsItemsMappedItem: any) => {
				return {
					'Codigo': additionalsItemsMappedItem.code,
					'GrupoId': additionalsItemsMappedItem.groupId,
					'TipoCalculoCustomizacao': additionalsItemsMappedItem.calcType,
					'Valor': additionalsItemsMappedItem.value
				}
			})
		})

		const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem: any) => {
			const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 3)
			const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 2)
			const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 1)

			const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem: any) => selectedAdditionalsMaximumItem.value))) || 0
			const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc: any, current: any) => Number(acc + current.value), [])) / selectedAdditionalsAverage.length : 0
			const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc: any, current: any) => {
				if (current.quantity > 1) {
					return Number(acc + (current.value * current.quantity))
				}

				return Number(acc + current.value)
			}, []) : 0

			return additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal
		})

		const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc: any, current: any) => Number(acc + current), [])) || 0
		const total = quantity * (value + (selectedAdditionalsGroupsTotal && selectedAdditionalsGroupsTotal))

		return {
			Codigo: id,
			Acompanhamentos: flatten(additionals),
			Quantidade: quantity,
			Observacao: notes,
			Valor: total,
			FidelidadeModeloPontosId: 0,
			FidelidadeProdutoPremioId: 0
		}
	})

	const schedulingId = data.searaSchedulingId || 0

	return {
		CartaoId: data.cartId,
		TipoPagamento: data.paymentType,
		Cpf: data.cpf,
		Cvv: data.cardCvv,
		ModalidadeRetiradaValor: data.deliveryFee || data.pickupFee,
		EnderecoDeliveryId: data.deliveryAddressId,
		BandeiraId: data.cardBrandId,
		PagarNaEntrega: data.deliveryPayment,
		Troco: data.change,
		CupomId: data.couponId,
		Ip: data.ip,
		FidelidadeModeloPontosId: data.fidelityScoreModelId,
		LocalEntregaBalcao: data.counterDeliveryPlace,
		AgendamentoSearaId: schedulingId,
		EstabelecimentoId: data.storeId,
		Produtos: products,
		ModalidadeRetirada: data.modality && data.modality.id,
		Konduto: data.konduto && {
			StatusChamada: data.konduto.requestStatus,
			StatusPedido: data.konduto.orderStatus,
			Recomendacao: data.konduto.recommendation,
			Score: data.konduto.score,
			IdPedidoKonduto: data.konduto.id
		},
		SubTotal: data.total,
		ComandaId: data.orderId
	}
}

export const mapOrderAnalysisRequest = (data: any): OrderAnalysisRequest => {
	const products = data.products && data.products.length && data.products.map((productsMappedItem: any) => {
		const {
			quantity,
			selectedAdditionals = [],
			notes = '',
			id,
			value
		} = productsMappedItem

		const additionals: any = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((additionalsMappedItem: any) => {
			return additionalsMappedItem.items && additionalsMappedItem.items.map((additionalsItemsMappedItem: any) => {
				return {
					Codigo: additionalsItemsMappedItem.code,
					GrupoId: additionalsItemsMappedItem.groupId,
					TipoCalculoCustomizacao: additionalsItemsMappedItem.calcType,
					Valor: additionalsItemsMappedItem.value
				}
			})
		})

		const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem: any) => {
			const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 3)
			const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 2)
			const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter((filteredItem: any) => filteredItem.calcType === 1)

			const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem: any) => selectedAdditionalsMaximumItem.value))) || 0
			const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc: any, current: any) => Number(acc + current.value), [])) / selectedAdditionalsAverage.length : 0
			const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc: any, current: any) => {
				if (current.quantity > 1) {
					return Number(acc + (current.value * current.quantity))
				}

				return Number(acc + current.value)
			}, []) : 0

			return additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal
		})

		const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc: any, current: any) => Number(acc + current), [])) || 0
		const total = quantity * (value + (selectedAdditionalsGroupsTotal && selectedAdditionalsGroupsTotal))

		return {
			Codigo: id,
			Acompanhamentos: flatten(additionals),
			Quantidade: quantity,
			Observacao: notes,
			Valor: total,
			FidelidadeModeloPontosId: 0,
			FidelidadeProdutoPremioId: 0
		}
	})

	const schedulingId = data.searaSchedulingId || 0

	return {
		CartaoId: data.cartId,
		TipoPagamento: data.paymentType,
		Cpf: data.cpf,
		Cvv: data.cardCvv,
		ModalidadeRetiradaValor: data.deliveryFee || data.pickupFee,
		EnderecoDeliveryId: data.deliveryAddressId,
		BandeiraId: data.cardBrandId,
		PagarNaEntrega: data.deliveryPayment,
		Troco: data.change,
		CupomId: data.couponId,
		Ip: data.ip,
		FidelidadeModeloPontosId: data.fidelityScoreModelId,
		LocalEntregaBalcao: data.counterDeliveryPlace,
		AgendamentoSearaId: schedulingId,
		EstabelecimentoId: data.storeId,
		Produtos: products,
		ModalidadeRetirada: data.modality && data.modality.id,
		SubTotal: data.total,
		ComandaId: data.orderId
	}
}

export const mapOrderAnalysisData = (data: any): OrderAnalysisData => {
	return {
		orderId: data['ComandaId'],
		success: data['Sucesso'],
		konduto: {
			id: data['IdPedidoKonduto'],
			requestStatus: data['StatusChamada'],
			orderStatus: data['StatusPedido'],
			recommendation: data['Recomendacao'],
			score: data['Score']
		}
	}
}

export const mapOrderCreateData = (data: any): OrderCreateData => {
	return {
		orderId: data['ComandaBalcaoId'],
		cnpj: data['Cnpj'],
		success: data['Sucesso'],
		message: data['Mensagem'],
		paymentStatus: data['StatusPagamento']
	}
}

export const mapLoginCreateData = (data: any, index: number): LoginData => {
	return {
		id: data['UsuarioId'],
		name: data['Nome'],
		success: data['Sucesso'],
		subscriptionStatus: data['StatusCadastro'],
		lastName: data['Sobrenome'],
		email: data['Email'],
		cpf: data['Cpf'],
		phone: data['Telefone'],
		birthDay: data['DataNascimento'],
		loginType: data['TipoLogin'],
		accessToken: data['Token'] && data['Token']['AccessToken'],
		message: data['Mensagem']
	}
}

export const mapLoginCreateRequest = (data: any): LoginCreateRequest => {
	const {
		email,
		password
	} = data

	return {
		"Email": email,
		"Senha": password
	}
}


interface ReceiptStatusNotification {
	status: number
	statuses: ReceiptStatusNotificationStatuses[]
}

interface ReceiptStatusNotificationStatuses {
	status: string
	time: string
	active: boolean
}

interface ReceiptStatusData {
	notification: ReceiptStatusNotification
	completed: boolean
	followLink: string
}

interface StatusAntiFruad {
	id: number
	available: boolean
	success: boolean,
	mensage: string
}

interface ReceiptRequestNumber {
	requestNumber: number
}

export const mapReceiptRequestNumber = (response: any): ReceiptRequestNumber => {
	return {
		requestNumber: response['NumeroChamada']
	}
}

export const mapReceiptStatusData = (data: any): ReceiptStatusData => {
	const statuses = (statuses: any[]) => (statuses.map((mappedItem: any) => ({
		status: mappedItem['Status'],
		active: mappedItem['Ativo'],
		time: mappedItem['Horario']
	})))

	const notification = {
		status: data['NotificacaoPedido'] && data['NotificacaoPedido']['StatusDelivery'],
		statuses: data['NotificacaoPedido'] && statuses(data['NotificacaoPedido']['ListaStatusDelivery']),
		deliveryTime: data['NotificacaoPedido'] && data['NotificacaoPedido']['PrevisaoEntregaTexto']
	}

	const followLink = data['NotificacaoPedido'] ? (data['NotificacaoPedido']['AcompanhamentoPedidoDisponivel'] ? data['NotificacaoPedido']['LinkAcompanhamentoPedido'] : '') : ''

	return {
		completed: data['PedidoPronto'],
		followLink,
		notification
	}
}

export const mapStatusAntiFraud = (data: any): StatusAntiFruad => {
	return {
		id: data['Id'],
		available: data['Disponivel'],
		success: data['Sucesso'],
		mensage: data['Mensagem']
	}
}

export const mapReceiptData = (data: any): ReceiptData => {
	const statuses = data['NotificacaoPedido'] && data['NotificacaoPedido']['ListaStatusDelivery'].map((mappedItem: any) => {
		return {
			time: mappedItem['Horario'],
			status: mappedItem['Status'],
			active: mappedItem['Ativo']
		}
	})

	const notification = data['NotificacaoPedido'] && {
		status: data['NotificacaoPedido']['StatusDelivery'],
		statuses,
		deliveryTime: data['NotificacaoPedido']['PrevisaoEntregaTexto']
	}

	const store = data['Estabelecimento'] && {
		id: data['Estabelecimento']['EstabelecimentoId'],
		name: data['Estabelecimento']['Nome'],
		address: data['Estabelecimento']['Endereco'],
		logoUrl: data['Estabelecimento']['UrlLogo'],
		whatsapp: data['Estabelecimento']['NumeroWhatsapp'],
		phone: data['Estabelecimento']['Telefone']
	}

	const scheduling = data["DataAgendamento"] || null;


	const items = data['PedidoItems'] && data['PedidoItems'].length && data['PedidoItems'].map((mappedItem: any) => {
		return {
			productId: mappedItem['ProdutoId'],
			product: mappedItem['Produto'],
			quantity: mappedItem['Quantidade'],
			description: mappedItem['Descricao'],
			value: mappedItem['Valor'],
			orderId: mappedItem['PedidoId'],
			additionalText: mappedItem['TextoAcompanhamento'],
			notes: mappedItem['Observacao'],
			additionals: mappedItem['Acompanhamentos'],
			fractionalQuantity: mappedItem['QuantidadeFracionada']
		}
	})

	return {
		list: items,
		store,
		cardBrand: data['BandeiraCartao'],
		cardNumber: data['NumeroCartao'],
		requestNumber: data['NumeroChamada'],
		scheduling,
		status: data['NumeroChamada'] > -1 ? 2 : 1,
		paymentStatus: data['StatusPagamento'],
		orderDate: data['DataComprovante'],
		deliveryAddress: data['LocalEntrega'],
		notification,
		modalityId: data['ModalidadeRetiradaId'],
		userName: data['Nome'],
		isPaid: !!data['ComandaPaga'],
		deliveryDiscount: data['DescontoValorModalidadeRetirada'],
		earnedPoints: data['PontosGanhos'],
		summary: {
			total: data['ValorPago'],
			deliveryFee: data['ModalidadeRetiradaValor'],
			discount: data['ValorDesconto'],
			subtotal: data['ValorPago'] - data['ModalidadeRetiradaValor']
		},
		success: data['Sucesso'],
		cpf: data['Cpf']
	}
}

const mapApiCardToModel = (card: any): Card => {
	return {
		id: card["CartaoId"],
		data: card["Dados"],
		brand: card["Bandeira"],
		brandId: card["BandeiraId"],
		paymentType: card["TipoPagamento"],
		cvvRequired: card["PedirCvv"],
		cardType: card["TipoCartao"],
		active: card["Ativo"],
		discount: card["Desconto"],
		discountPercentage: card["ValorDesconto"],
		discountMaxValue: card["ValorMaximo"],
		blocked: card["Ativo"] ? false : true
	}
}

export const mapCardsData = (response: any): CardsData => {

	const userCards =
		response["CartoesUsuario"] &&
		response["CartoesUsuario"].find((card: any) => card["Tipo"] === 'Cartão de Crédito e Vale Refeição' || card["Tipo"] === 'VR Refeição');



	const availableOnlineCards = userCards &&
		userCards["Cartoes"]
			.map((card: any): Card => {
				console.log(mapApiCardToModel(card))
				return mapApiCardToModel(card);
			});


	const offlinePayments =
		response["PagamentosNaEntrega"] &&
		response["PagamentosNaEntrega"].find((card: any) => card["Tipo"] === 'Pagamento Presencial');


	const availableOfflineCards = offlinePayments &&
		offlinePayments["Cartoes"]
			.map((card: any): Card => mapApiCardToModel(card))

	const selectedCard = {
		id: response['CartaoSelecionado'] && response['CartaoSelecionado']['CartaoId'],
		number: response['CartaoSelecionado'] && response['CartaoSelecionado']['Dados'],
		brand: response['CartaoSelecionado'] && response['CartaoSelecionado']['Imagem'],
		brandName: response['CartaoSelecionado'] && response['CartaoSelecionado']['Bandeira'],
		brandId: response['CartaoSelecionado'] && response['CartaoSelecionado']['BandeiraId'],
		cvv: '',
		cvvIsRequired: response['CartaoSelecionado'] && response['CartaoSelecionado']['PedirCvv'],
		status: response['CartaoSelecionado'] && response['CartaoSelecionado']['Ativo'],
		paymentType: response['CartaoSelecionado'] && response['CartaoSelecionado']['TipoPagamento']
	}

	return {
		success: response["Sucesso"],
		availableOnlineCards,
		availableOfflineCards,
		selectedCard
	}
}


export const mapDeliveryDates = (response: any): any => {


	const dates = response["DatasDisponiveis"].map((item: any) => ({
		date: item["Data"],
		hours: item["Horarios"].map((hourItem: any) => {
			const [hour, minute] = hourItem["Horario"].split(":");
			const initDate = new Date(new Date(item["Data"]).setHours(
				parseInt(hour),
				parseInt(minute)
			));
			const endDate = addMinutes(initDate, response["PrazoTolerancia"] | 0);

			const parseInitDate = `${initDate.getHours()}`.padStart(2, '0') + ':' + `${initDate.getMinutes()}`.padStart(2, '0');
			const parseEndDate = `${endDate.getHours()}`.padStart(2, '0') + ':' + `${endDate.getMinutes()}`.padStart(2, '0');

			return {
				hour: hourItem["Horario"],
				hourRange: parseInitDate + ' - ' + parseEndDate,
				id: hourItem["Id"],
			};
		}),
	}));

	const message = response["Mensagem"];

	const tolerance = response["PrazoTolerancia"];

	return {
		success: response["Sucesso"],
		dates,
		message,
		tolerance,
	}
}

interface MundipaggAccessTokenData {
	cardToken: string;
	firstDigits: string;
	lastDigits: string;
}

export const mapMundipaggAccessTokenData = (data: any): MundipaggAccessTokenData => {
	return {
		cardToken: data['id'],
		firstDigits: data['card']['first_six_digits'],
		lastDigits: data['card']["last_four_digits"],
	}
}

export const mapCardToMundipaggApiRequest = (data: any): any => {
	return {
		number: data.number,
		holder_name: data.name,
		holder_document: data.cpf,
		exp_month: data.date && data.date.slice(0, 2),
		exp_year: data.date && data.date.slice(-2),
		brand: CARD_BRANDS_TAG[data.brandId],
		cvv: data.CVV,
		private_label: false,
		options: {
			verify_card: false
		}
	}
}

export const mapAddTicketCardRequestToApi = (card: any): any => {
	return {
		"DataNascimento": card.birth,
		"Numero": card.number,
		"Portador": card.name
	}
}

export const mapAddTicketCardResponse = (response: any): AddCardResponse => {
	return {
		success: response["Sucesso"],
		message: response["Mensagem"]
	}
}


export const mapDeleteCardRequestToApi = (card: Card): any => {
	return {
		"CartaoId": card.id,
		"BandeiraId": card.brandId
	}
}

export const mapDeleteCardResponse = (response: any): deleteCardResponse => {
	return {
		success: response["Sucesso"],
		message: response["Mensagem"]
	}
}

export const mapAddCardRequestToApi = (data: AddCardData): any => {
	return {
		'CartaoIdMundiPagg': data.mundipaggCardId,
		'UsuarioIdMundiPagg': data.mundipaggUserId,
		'Digitos': data.digits,
		'Bandeira': data.brandId,
		'TipoCartao': data.type,
		'Documento': data.cpf,
		'PrimeirosDigitos': data.firstDigits,
		'UltimosDigitos': data.digits,
		'MesValidade': data.expMonth,
		"PortadorCartao": data.name,
		'AnoValidade': data.expYear
	}
}

export const mapAddCardResponse = (data: any): AddCardResponse => {
	return {
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

interface SignUpCreateRequest {
	name?: string
	lastName?: string
	email?: string
	password?: string
	password2?: string
	birthDate?: string
	CPF?: string
	phone?: string
	googleUserId?: string
	googleToken?: string
	facebookUserId?: string
	facebookToken?: string
	image?: string
}

export const mapSignUpCreateRequest = (data: SignUpCreateRequest): any => {
	return {
		'Nome': data.name,
		'SobreNome': data.lastName,
		'Email': data.email,
		'Senha': data.password,
		'ConfirmaSenha': data.password2,
		'DataNascimento': data.birthDate,
		'Cpf': data.CPF,
		'Telefone': data.phone,
		'GoogleUsuarioId': data.googleUserId,
		'GoogleToken': data.googleToken,
		'FacebookUsuarioId': data.facebookUserId,
		'FacebookToken': data.facebookToken,
		'FotoId': data.image
	}
}

interface SignUpCreateData {
	id: number
	authToken: string
	name: string
	lastname: string
	email: string
	cpf: string
	phone: string
	birthdate: string
	mustUpdate: boolean
	hasUpdate: boolean
	signUpStatus: number
	subscriptionStatus: number,
	signUp: boolean
	updateData: boolean
	loginType: number
	token: {
		accessToken: string
		type: string
		expires: string
	}
	success: boolean
	message: string
}

export const mapSignUpCreateData = (data: any): SignUpCreateData => {
	const expiresSeconds = data['Token'] && data['Token']['ExpiresIn']

	return {
		id: data['UsuarioId'],
		authToken: data['AuthenticationToken'],
		name: data['Nome'],
		lastname: data['SobreNome'],
		email: data['Email'],
		cpf: data['Cpf'],
		phone: data['Telefone'],
		birthdate: data['DataNascimento'],
		mustUpdate: data['AtualizacaoObrigatoria'],
		hasUpdate: data['TemAtualizacao'],
		signUpStatus: data['StatusCadastro'],
		subscriptionStatus: data['StatusCadastro'],
		signUp: data['Cadastrar'],
		updateData: data['AtualizarDados'],
		loginType: data['TipoLogin'],
		token: {
			accessToken: data['Token'] && data['Token']['AccessToken'],
			type: data['Token'] && data['Token']['TokenType'],
			expires: moment().add('seconds', expiresSeconds).toISOString()
		},
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

export const mapFbLoginFormToAPI = (data: any): any => {
	return {
		'FacebookToken': data.accessToken,
		'Email': data.email,
		'FacebookUsuarioId': data.userID
	}
}

export const mapGoogleLoginFormToAPI = (data: any): any => {
	return {
		'GoogleToken': data.accessToken,
		'Email': data.email,
		'GoogleUsuarioId': data.sub
	}
}

export const mapAuthAPIResponse = (data: any): any => {
	const expiresSeconds = data['Token'] && data['Token']['ExpiresIn']

	return {
		user: {
			id: data['UsuarioId'],
			authToken: data['AuthenticationToken'],
			name: data['Nome'],
			lastname: data['SobreNome'],
			email: data['Email'],
			cpf: data['Cpf'],
			phone: data['Telefone'],
			birthdate: data['DataNascimento'],
			mustUpdate: data['AtualizacaoObrigatoria'],
			hasUpdate: data['TemAtualizacao'],
			signUpStatus: data['StatusCadastro'],
			subscriptionStatus: data['StatusCadastro'],
			signUp: data['Cadastrar'],
			updateData: data['AtualizarDados'],
			loginType: data['TipoLogin'],
			token: {
				accessToken: data['Token'] && data['Token']['AccessToken'],
				type: data['Token'] && data['Token']['TokenType'],
				expires: moment().add('seconds', expiresSeconds).toISOString()
			}
		},
		subscriptionStatus: data['StatusCadastro'],
		signUp: data['Cadastrar'],
		success: data['Sucesso'],
		message: data['Mensagem']
	}
}

export const mapPhoneFormToAPI = (data: any): any => {
	return {
		'Telefone': data.fullPhone || data.phone,
		'UsuarioId': data.userId
	}
}

export const mapCodeFormToAPI = (data: any): any => {
	return {
		'Codigo': data.code
	}
}

export const mapStoreData = (data: any, index: number): StoreData => {
	const products = data['Categorias'] && data['Categorias'][index] && data['Categorias'][index]['Produtos'] && data['Categorias'][index]['Produtos'].length && data['Categorias'][index]['Produtos'].map(mapStoreDataProducts)

	const categories = data['Categorias'] && data['Categorias'].length && data['Categorias'].map((mappedItem: any) => {
		const products = mappedItem['Produtos'].length && mappedItem['Produtos'].map(mapStoreDataProducts)

		return {
			id: mappedItem['Id'],
			name: mappedItem['Nome'],
			products
		}
	})

	const searaAvailableDates = data['HorariosDisponiveisSeara'] && data['HorariosDisponiveisSeara'].map((mappedItem: any) => {
		const day = mappedItem['Dia']
		const date = mappedItem['Data']
		const schedules = mappedItem['Horarios'] && mappedItem['Horarios'].map((mappedItem: any) => {
			const id = mappedItem['Id']
			const time = mappedItem['Hora']

			return {
				id,
				time
			}
		})

		return {
			day,
			date,
			schedules
		}
	})


	const loyaltyPoints = data['FidelidadeModelosPontos'] && data['FidelidadeModelosPontos'].map((mappedItem: any) => {
		const loyaltyModelPointsId = mappedItem['FidelidadeModeloPontosId']
		const modelPoints = mappedItem['ModeloPontos']
		const points = mappedItem['Pontos']
		const minValue = mappedItem['ValorMinimo']

		return {
			loyaltyModelPointsId,
			modelPoints,
			points,
			minValue
		}
	})

	const images = (data['Imagem'] && {
		logo: data['Imagem']['Logo'],
		headerBackgroundImage: data['Imagem']['Empresa']
	}) || {}

	return {
		id: data['Id'],
		name: data['Nome'],
		address: data['Localizacao'] && data['Localizacao']['Endereco'],
		opened: data['Aberto'],
		status: data['StatusEstabelecimento'],
		logo: data['Imagem'] && data['Imagem']['Logo'],
		deliveryTime: data['EstimativaEntrega'],
		adHoc: data['PagamentoAdHoc'],
		aisle: data['CardapioCorredor'],
		deliveryMinimumValue: 0,
		pickupMinimumValue: 0,
		deliveryFee: 0,
		pickupFee: 0,
		selectedCategory: 0,
		cart: null,
		categories,
		allCategories: categories,
		products,
		images,
		searaAvailableDates,
		loyaltyPoints
	}
}

const mapStoreDataProducts = (data: any) => {
	const additionals = data['Acompanhamentos'] && data['Acompanhamentos'].length && data['Acompanhamentos'].map((additionalsMappedItem: any) => {
		const additionalsItems = additionalsMappedItem['Itens'] && additionalsMappedItem['Itens'].length && additionalsMappedItem['Itens'].map((additionalsItemsMappedItem: any) => {
			return {
				counter: additionalsItemsMappedItem['Balcao'],
				delivery: additionalsItemsMappedItem['Delivery'],
				description: additionalsItemsMappedItem['Descricao'],
				name: additionalsItemsMappedItem['Nome'],
				value: additionalsItemsMappedItem['Valor'],
				packPrice: additionalsItemsMappedItem['ValorEmbalagem'],
				code: additionalsItemsMappedItem['Codigo'],
				calcType: additionalsMappedItem['TipoCalculoCustomizacao'],
				groupId: additionalsItemsMappedItem['GrupoId'],
				minimum: additionalsMappedItem['Minimo'],
				maximum: additionalsMappedItem['Maximo']
			}
		})

		return {
			name: additionalsMappedItem['Nome'],
			minimum: additionalsMappedItem['Minimo'],
			maximum: additionalsMappedItem['Maximo'],
			checkbox: additionalsMappedItem['Checkbox'],
			calcType: additionalsMappedItem['TipoCalculoCustomizacao'],
			promotional: additionalsMappedItem['Promotional'],
			groupId: additionalsItems[0].groupId,
			items: additionalsItems
		}
	})

	return {
		id: data['Id'],
		name: data['Nome'],
		description: data['Descricao'],
		value: data['Valor'],
		image: data['Imagem'],
		promotionalValue: data['ValorPromocional'],
		rewardImage: data['ImagemPremio'],
		showFromValueText: data['ExibirApartirDe'],
		fromValue: data['ValorApartirDe'],
		packPrice: data['ValorEmbalagem'],
		additionals
	}
}

export const mapAntiFraudToApi = (antiFraud: any): antiFraudeConfirmation => {
	return {
		ComandaId: antiFraud.orderId,
		Id: antiFraud.antifraudId
	}
}

export const mapAntiFraudConfirm = (response: any): antiFraudeApi => {
	return {
		success: response["Sucesso"],
		message: response["Mensagem"]
	}
}
