import React from 'react'
import { Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	pipe
} from '../../../domain/helpers'
import {
	CustomImage
} from '../../components'

import NoProduct from '../../assets/no-product-card.png'

import {
	setQuantity
} from '../../../redux/actions/main'

import {
	useLayout,
	useSidebar
} from '../../hooks'

import { addressActions, user } from '../../../state'

import {
	Wrapper,
	CardProductDescription,
	CardProductName,
	CardProductPrice,
	CardProductPriceContainer,
	CardProductPromotionalPrice,
	CardProductText,
	IncludeButton,
	ProductImage,
	TagWrapper,
	LabelRectangular,
	LabelCircular,
	TagOne,
	TagTwo,
	TagLoyaltyWrapper,
	LabelLoyaltyCircular,
	CashbackWrapper,
	LabelCashback
} from './styles'
import { HAS_CASHBACK, HAS_LOYALTY, IMAGE_ELEVATE } from '../../../config'
import Selo from '../../assets/icone_menu_fidelidade.png'

export function VerticalCardProductComponent(props) {
	const {
		index,
		id,
		handleView,
		handleAdd,
		name,
		description,
		value,
		image,
		promotionalValue,
		categories,
		status,
		setQuantity,
		quantity,
		opened,
		className,
		hasRequiredAdditionals,
		address,
		setUserCallback,
		setAddressPostalCodeRequest,
		additionals,
		selectedAdditionals,
		category,
		rawPrice,
		accessToken,
		setAddressRequest,
		setNoAddressesList,
		tags,
		showFromValueText,
		fromValue,
		modalityId,
		categorieLength,
		loyaltyModelPointsId,
		loyaltyPoints
	} = props

	const {
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

	function handleQtd(args) {
		setQuantity(args)
	}

	const checkPromotionalValueMaxLength = () => {
		return (promotionalValue && promotionalValue.length >= 6 || value.length >= 6);
	}
	return (
		<Wrapper className={className} opened={opened} categorieLength={categorieLength} categories={categories}>
			{HAS_LOYALTY === 'true' && loyaltyModelPointsId > 0 && loyaltyPoints > 0 ? <>
				<TagLoyaltyWrapper itens={tags}>
					<TagOne>
						<LabelLoyaltyCircular backgroundColor={"#C2C2C2"} fontColor={"#949494"} key={`${id}-selo`}>
							<img src={Selo} width="23px" alt="Sele de pontos" /><span>x{loyaltyPoints}</span>
						</LabelLoyaltyCircular>
					</TagOne>
				</TagLoyaltyWrapper>
			</> : null}
			{HAS_CASHBACK === 'true' && loyaltyModelPointsId > 0 && loyaltyPoints > 0 ? <>
				<CashbackWrapper itens={tags}>
					<TagOne>
						<LabelCashback backgroundColor={"#C2C2C2"} fontColor={"#949494"} key={`${id}-selo`}>
							{loyaltyPoints}% de Cashback
						</LabelCashback>
					</TagOne>
				</CashbackWrapper>
			</> : null}
			{tags && tags.length > 0 ? <TagWrapper itens={tags}>
				<TagOne>
					{tags && tags.length > 0 ? tags.map((item, index) => {
						const {
							type,
							format
						} = item

						const key = `product-card-tag-${index}-${type}-${format}`

						if (type === 1) {
							if (format === 2) return <LabelRectangular backgroundColor={item.backgroundColor} fontColor={item.fontColor} key={key}><span>{item.text}</span></LabelRectangular>
							return <LabelCircular backgroundColor={item.backgroundColor} fontColor={item.fontColor} key={key}><span>{item.text}</span></LabelCircular>
						}

						return null
					}) : null}
				</TagOne>
				<TagTwo>
					{tags && tags.length > 0 ? tags.map((item) => {
						const {
							type,
							format
						} = item

						const key = `product-card-tag-${index}-${type}-${format}`

						if (type === 2) {
							if (format === 2) return <LabelRectangular backgroundColor={item.backgroundColor} fontColor={item.fontColor} key={key}><span>{item.text}</span></LabelRectangular>
							return <LabelCircular backgroundColor={item.backgroundColor} fontColor={item.fontColor} key={key}><span>{item.text}</span></LabelCircular>
						}

						return null
					}) : null}
				</TagTwo>
			</TagWrapper> : null}
			{image ? <ProductImage className={`${IMAGE_ELEVATE === 'true' ? '' : 'no-elevate'}`}
				onClick={handleView}><CustomImage src={image} alt={name} fallbackSrc={NoProduct} /></ProductImage> :
				<ProductImage className='noimg' onClick={handleView}>
					<CustomImage className='noimg' src={NoProduct} alt={name} onClick={handleView} fallbackSrc={NoProduct} />
				</ProductImage>}
			{name && name.length >= 50 ? <Tooltip title={name} placement="top" arrow>
				<CardProductName onClick={handleView}>{name.substring(0, 46) + '...'}</CardProductName>
			</Tooltip > : <CardProductName onClick={handleView}>{name}</CardProductName>}
			<CardProductDescription onClick={handleView}>{description && description.length > 50 ? description.substring(0, 60) + '...' : description}</CardProductDescription>
			<CardProductPriceContainer className={`${IMAGE_ELEVATE === 'true' ? 'elevate' : ''}`}>
				{showFromValueText && <CardProductText>A partir de:</CardProductText>}
				<div style={
					{
						display: 'flex',
						alignItems: checkPromotionalValueMaxLength() ? 'flex-start' : 'center',
						flexDirection: checkPromotionalValueMaxLength() ? 'column-reverse' : 'row',

					}}>
					{!promotionalValue && <CardProductPrice className="value"><span>R$</span> {showFromValueText ? fromValue : value}</CardProductPrice>}
					{promotionalValue && <CardProductPrice className={promotionalValue ? 'promo' : 'value'}><span>R$</span> {promotionalValue ? promotionalValue : value}</CardProductPrice>}
					{promotionalValue && <CardProductPromotionalPrice max={checkPromotionalValueMaxLength()}><span>R$</span>{value}</CardProductPromotionalPrice>}
				</div>
				{status && status === 1 ? (!quantity ? <IncludeButton onClick={(event) => {
					if (hasRequiredAdditionals) {
						handleView(event)

						return
					}

					if (modalityId === 4) {
						if (address && address.main && address.verified) {
							handleAdd(event)
						} else {
							if (accessToken) {
								setAddressRequest(true)
								setAddressPostalCodeRequest(false)
								setNoAddressesList(false)
							} else {
								setAddressPostalCodeRequest(true)
								setAddressRequest(false)
							}

							setUserCallback({
								name: 'add-product',
								data: {
									index,
									id,
									name,
									description,
									image,
									value: rawPrice,
									quantity: 1,
									additionals,
									selectedAdditionals,
									category,
									promotionalValue,
									loyaltyModelPointsId,
									loyaltyPoints
								}
							})
						}
					} else {
						handleAdd(event)
					}

				}}>Incluir</IncludeButton> : <IncludeButton onClick={(event) => {
					if (quantity === 999) {
						return
					}
					if (hasRequiredAdditionals) {
						handleView(event)

						return
					}

					if (address && address.main && address.verified) {
						handleQtd({
							id,
							quantity: 1,
							additionalsEmpty: true
						})

						handleLayoutMode(layoutModeEnum.sideFullContent)
						handleSidebarContent(sidebarContentEnum.cart)
						handleSidebarOpened(true)
					} else {
						if (accessToken) {
							setAddressRequest(true)
							setAddressPostalCodeRequest(false)
							setNoAddressesList(false)
						} else {
							setAddressPostalCodeRequest(true)
							setAddressRequest(false)
						}

						setUserCallback({
							name: 'add-product',
							data: {
								index,
								id,
								name,
								description,
								image,
								value: rawPrice,
								quantity: 1,
								additionals,
								selectedAdditionals,
								category,
								promotionalValue,
								loyaltyModelPointsId,
								loyaltyPoints
							}
						})
					}
				}}>Incluir</IncludeButton>) :
					<IncludeButton className='disabled' onClick={(event) => {
						if (hasRequiredAdditionals) {
							handleView(event)

							return
						}

						handleAdd(event)
					}}>Incluir</IncludeButton>}
			</CardProductPriceContainer>
		</Wrapper>
	)
}

VerticalCardProductComponent.propTypes = {
	setAddressRequest: PropTypes.func,
	accessToken: PropTypes.string,
	index: PropTypes.string,
	id: PropTypes.number,
	handleView: PropTypes.func,
	handleAdd: PropTypes.func,
	name: PropTypes.string,
	description: PropTypes.string,
	value: PropTypes.string,
	rawPrice: PropTypes.number,
	image: PropTypes.string,
	promotionalValue: PropTypes.string,
	categories: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.bool, PropTypes.string]),
	status: PropTypes.number,
	setQuantity: PropTypes.func,
	quantity: PropTypes.number,
	opened: PropTypes.bool,
	className: PropTypes.string,
	hasRequiredAdditionals: PropTypes.bool,
	address: PropTypes.shape({
		main: PropTypes.string,
		verified: PropTypes.bool
	}),
	setUserCallback: PropTypes.func,
	setAddressPostalCodeRequest: PropTypes.func,
	additionals: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.number]),
	selectedAdditionals: PropTypes.arrayOf(PropTypes.shape({})),
	category: PropTypes.shape({}),
	setNoAddressesList: PropTypes.func,
	tags: PropTypes.arrayOf(PropTypes.shape({}))
}

const mapStateToProps = (state) => {
	return {
		selectedCategory: state.store.store && state.store.store.selectedCategory,
		address: state.address.address && state.address.address,
		accessToken: state.user.accessToken || '',
		modalityId: state.main.modality && state.main.modality.id,
	}
}

const GetConnection = connect(mapStateToProps, {
	setUserCallback: user.setUserCallback,
	setAddressPostalCodeRequest: addressActions.setAddressPostalCodeRequest,
	setAddressRequest: addressActions.setAddressRequest,
	setNoAddressesList: addressActions.setNoAddressesList,
	setQuantity
})

export function VerticalCardProduct(props) {
	const MemoizedComponent = React.memo(VerticalCardProductComponent)
	const Component = pipe(
		GetConnection
	)(MemoizedComponent)

	return <Component {...props} />
}
