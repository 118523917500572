import { loading, userAddress } from "..";
import { actionTypes } from "../";
import { calculateCartTotal } from "../../domain/helpers";
import { calculatePackPrice } from "../../domain/helpers/products/CalculatePackPrice";
import { setNotification } from "../../redux/actions/main";

export const setOrders = (orders) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_ORDERS,
		payload: orders,
	});
};

export const setOrderHistory = (orderHistory) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_ORDER_HISTORY,
		payload: orderHistory,
	});
};

export const setOrder = (order) => (dispatch) => {
	dispatch({
		type: actionTypes.SET_ORDER,
		payload: order,
	});
};

export const fetchOrders = (callback) => (dispatch, getState, container) => {
	try {
		const stateOrders = getState().main.orders || {};

		const data = {};

		const actions = {
			setLoading: loading.setLoading,
		};

		return container.fetchOrders({
			onSuccess: ({ orders }) => {
				if (orders && orders.orders) {
					dispatch(
						setOrders({
							...stateOrders,
							orders: orders.orders,
							retry: orders.retry,
						})
					);

					return;
				}

				dispatch(
					setOrders({
						orders: [],
						retry: false,
					})
				);
			},
			onError: () => {
				dispatch(
					setOrders({
						orders: [],
						retry: false,
					})
				);
			},
			onEmpty: () => {
				dispatch(
					setOrders({
						orders: [],
						retry: false,
					})
				);
			},
			dispatch,
			data,
			actions,
		});
	} catch (e) {
		console.log(e);

		dispatch(
			setOrders({
				orders: [],
				retry: true,
			})
		);
	} finally {
		callback && callback();
	}
};

export const fetchOrder = (callback) => (dispatch, getState, container) => {
	try {
		const orderId = getState().main.order && getState().main.order.id;
		const partnerOrderId =
			getState().main.params && getState().main.params.partnerOrderId;

		const data = {
			orderId,
			partnerOrderId,
		};

		const actions = {
			setLoading: loading.setLoading,
		};

		const done = () => {
			dispatch(loading.setLoading({ item: "order", delete: true }));
		};

		return container.fetchOrder({
			onSuccess: ({ order }) => {
				if (order) {
					dispatch(
						setOrder({
							...order,
						})
					);
				}

				done();
			},
			onError: () => {
				dispatch(setOrder(null));

				done();
			},
			onEmpty: () => {
				dispatch(setOrder(null));

				done();
			},
			dispatch,
			data,
			actions,
		});
	} catch (e) {
		console.log(e);

		dispatch(setOrder(null));
	} finally {
		callback && callback();
	}
};

export const fetchOrderHistory =
	(callback) => (dispatch, getState, container) => {
		try {
			const stateOrderHistory = getState().main.orderHistory || {};

			const { pagination } = stateOrderHistory;

			const data = {
				pagination,
			};

			const actions = {
				setLoading: loading.setLoading,
			};

			const done = () => {
				dispatch(loading.setLoading({ item: "order-history", delete: true }));
			};

			return container.fetchOrderHistory({
				onSuccess: ({ orders }) => {
					if (orders && orders.orders && orders.orders.length) {
						dispatch(
							setOrderHistory({
								...stateOrderHistory,
								orders: orders.orders,
								message: orders.message,
								status: orders.status,
							})
						);

						done();

						return;
					}

					dispatch(
						setOrderHistory({
							orders: [],
							message: "",
							status: false,
						})
					);

					done();
				},
				onError: () => {
					dispatch(
						setOrderHistory({
							orders: [],
							message: "",
							status: false,
						})
					);

					done();
				},
				onEmpty: () => {
					dispatch(
						setOrderHistory({
							orders: [],
							message: "",
							status: false,
						})
					);

					done();
				},
				dispatch,
				data,
				actions,
			});
		} catch (e) {
			console.log(e);

			dispatch(
				setOrderHistory({
					orders: [],
					message: "",
					status: false,
				})
			);
		} finally {
			callback && callback();
		}
	};

const addAnalysis =
	(callback, data) => async (dispatch, getState, container) => {
		const callbackError =
			callback && callback[0] && typeof callback[0] === "function"
				? callback[0]
				: () => {};
		const callbackSuccess =
			callback && callback[0] && typeof callback[1] === "function"
				? callback[1]
				: () => {};

		try {
			const actions = {
				setLoading: loading.setLoading,
			};

			return container.addAnalysis({
				onSuccess: ({ analysis }) => {
					if (!analysis.success) {
						const analysis = {
							konduto: null,
							orderId: null,
							success: false,
						};

						dispatch(
							setOrder({
								analysis,
							})
						);

						callbackError && callbackError();

						return;
					}

					if (analysis.success) {
						dispatch(
							setOrder({
								analysis,
							})
						);

						callbackSuccess && callbackSuccess();

						return;
					}
				},
				onError: () => {
					callbackError && callbackError();
				},
				onEmpty: () => {
					callbackError && callbackError();
				},
				dispatch,
				data,
				actions,
			});
		} catch (e) {
			console.log(e);

			callbackError && callbackError();
		}
	};

export const addOrder = (callback) => async (dispatch, getState, container) => {
	const callbackError =
		callback &&
		callback.filter((filteredItem) => filteredItem.name === "order") &&
		callback.filter((filteredItem) => filteredItem.name === "order")[0] &&
		callback.filter((filteredItem) => filteredItem.name === "order")[0]
			.callback;
	const callbackSuccess =
		callback &&
		callback.filter((filteredItem) => filteredItem.name === "order") &&
		callback.filter((filteredItem) => filteredItem.name === "order")[1] &&
		callback.filter((filteredItem) => filteredItem.name === "order")[1]
			.callback;
	const addressCallback =
		callback &&
		callback.length &&
		callback.filter((filteredItem) => filteredItem.name === "address");

	try {
		const stateAddress = getState().address.address;
		const userAddressId = {
			value: getState().address.address && getState().address.address.id,
		};
		const storeId = getState().store.store && getState().store.store.id;
		const loyaltyModelPointsId =
			getState().store.store &&
			getState().store.store.loyaltyPoints[0] &&
			getState().store.store.loyaltyPoints[0].loyaltyModelPointsId;
		const products = getState().main.cart && getState().main.cart.products;
		const modality = getState().main.modality;
		const modalityId = getState().main.modality && getState().main.modality.id;
		const cartTotal = calculateCartTotal(products);
		const selectedDeliveryDate =
			getState().main.selectedDeliveryDate &&
			getState().main.selectedDeliveryDate.date;
		const selectedDeliveryDateTimeId =
			getState().main.selectedDeliveryDate &&
			getState().main.selectedDeliveryDate.hour &&
			getState().main.selectedDeliveryDate.hour.id;
		const userCard = getState().main.userCard;
		const deliveryFee =
			modality.id === 4
				? getState().store.store && getState().store.store.deliveryFee
				: 0;
		const pickupFee = modality.id === 2 ? calculatePackPrice(products) : 0;
		const couponId =
			(getState().main.cart &&
				getState().main.cart.coupon &&
				getState().main.cart.coupon.id) ||
			0;

		const specifiedAnalysisData = { value: {} };

		if (modalityId === 4 && !userAddressId.value) {
			await dispatch(userAddress.add(addressCallback));

			Object.assign(userAddressId, {
				value: getState().address.address && getState().address.address.id,
			});
		}

		Object.assign(specifiedAnalysisData, {
			value: {
				address: stateAddress,
				modality,
				products,
				storeId,
				cardBrandId: userCard.brandId,
				cardCvv: userCard.cvv,
				cardId: userCard.id,
				change: 0,
				counterDeliveryPlace: "",
				couponId,
				cpf: true,
				deliveryAddressId: userAddressId && userAddressId.value,
				deliveryPayment: userCard.isOfflinePayment,
				fidelityScoreModelId: loyaltyModelPointsId,
				ip: "",
				paymentType: userCard.paymentType,
				pickupFee,
				deliveryFee,
				total: cartTotal,
				selectedDeliveryDate: {
					date: selectedDeliveryDate,
					hourId: selectedDeliveryDateTimeId,
				},
			},
		});

		dispatch(setNotification(null));

		await dispatch(
			addAnalysis(callback, {
				...specifiedAnalysisData.value,
			})
		);

		const analysis = getState().main.order && getState().main.order.analysis;

		if (!analysis.success) {
			console.warn("Erro na análise");

			return;
		}

		const { konduto } = analysis;

		const specifiedOrderData = { value: {} };

		Object.assign(specifiedOrderData, {
			value: {
				address: stateAddress,
				konduto,
				modality,
				products,
				storeId,
				cardBrandId: userCard.brandId,
				cardCvv: userCard.cvv,
				cardId: userCard.id,
				change: 0,
				counterDeliveryPlace: "",
				couponId,
				cpf: true,
				deliveryAddressId: userAddressId && userAddressId.value,
				deliveryPayment: userCard.isOfflinePayment,
				fidelityScoreModelId: loyaltyModelPointsId,
				ip: "",
				orderId: analysis.orderId,
				paymentType: userCard.paymentType,
				pickupFee,
				deliveryFee,
				total: cartTotal,
			},
		});

		const data = { ...specifiedOrderData.value };
		const actions = {
			setLoading: loading.setLoading,
		};

		const done = () => {
			dispatch(loading.setLoading({ item: "add-order", delete: true }));
		};

		return container.addOrder({
			onSuccess: ({ order }) => {
				if (!order.success) {
					callbackError && callbackError({ order });
					done();

					return;
				}

				if (order.success) {
					dispatch(
						setOrder({
							analysis,
							id: order.orderId,
						})
					);

					callbackSuccess &&
						callbackSuccess({
							...order,
						});

					return;
				}
			},
			onError: () => {
				callbackError && callbackError();
				done();
			},
			onEmpty: () => {
				callbackError && callbackError();
			},
			dispatch,
			data,
			actions,
		});
	} catch (e) {
		console.log(e);

		callbackError && callbackError();
	}
};
