export const actionTypes = {
	SET_DEVICE: "SET_DEVICE",
	SET_SIDE_MODE: "SET_SIDE_MODE",
	SET_ADDITONALS: "SET_ADDITONALS",
	SET_CARD: "SET_CARD",
	SET_SAVED_CARD: "SET_SAVED_CARD",
	SET_CATEGORIES: "SET_CATEGORIES",
	SET_ERROR: "SET_ERROR",
	SET_GTM: "SET_GTM",
	SET_PIXEL: "SET_PIXEL",
	SET_HIGHLIGHTS: "SET_HIGHLIGHTS",
	SET_CARD_NAME: "SET_CARD_NAME",
	SET_CARD_NUMBER: "SET_CARD_NUMBER",
	SET_CARD_DATE: "SET_CARD_DATE",
	SET_CARD_CPF: "SET_CARD_CPF",
	SET_CARD_BRAND_ID: "SET_CARD_BRAND_ID",
	SET_CARD_CVV: "SET_CARD_CVV",
	SET_CARD_SAVED: "SET_CARD_SAVED",
	SET_MODALITY: "SET_MODALITY",
	SET_ORDER: "SET_ORDER",
	SET_ORDERS: "SET_ORDERS",
	SET_ORDER_HISTORY: "SET_ORDER_HISTORY",
	SET_ORDER_FINISH_DATA: "SET_ORDER_FINISH_DATA",
	SET_CONTACT_US: "SET_CONTACT_US",
	SET_CART: "SET_CART",
	SET_CART_COUPON: "SET_CART_COUPON",
	SET_COUPON: "SET_COUPON",
	SET_COUPONS: "SET_COUPONS",
	SET_PARAMS: "SET_PARAMS",
	SET_QUANTITY: "SET_QUANTITY",
	SET_LOGIN: "SET_LOGIN",
	SET_SEARCH: "SET_SEARCH",
	SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
	/* SET_SELECTED_PRODUCT: 'SET_SELECTED_PRODUCT', */
	SET_SIGN_UP: "SET_SIGN_UP",
	SET_SIGN_IN: "SET_SIGN_IN",
	SET_STORE: "SET_STORE",
	SET_STORE_FEES: "SET_STORE_FEES",
	SET_STORES: "SET_STORES",
	SET_USER_CARD: "SET_USER_CARD",
	SET_USER_CARD_CVV: "SET_USER_CARD_CVV",
	SET_USER_CARDS: "SET_USER_CARDS",
	SET_USER_HISTORY: "SET_USER_HISTORY",
	SET_USER_ORDER_HISTORY: "SET_USER_ORDER_HISTORY",
	SET_USER_NAME: "SET_USER_NAME",
	SET_NOTIFICATION: "SET_NOTIFICATION",
	SET_RECEIPT: "SET_RECEIPT",
	SET_USER_SCHEDULING: "SET_USER_SCHEDULING",
	ADD_PRODUCT: "ADD_PRODUCT",
	REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
	REMOVE_SELECTED_PRODUCT_ADDITIONAL: "REMOVE_SELECTED_PRODUCT_ADDITIONAL",
	REMOVE_PRODUCT: "REMOVE_PRODUCT",
	SET_PAGINATION: "SET_PAGINATION",
	SET_STORE_SETTINGS: "SET_STORE_SETTINGS",
	SET_SCROLL: "SET_SCROLL",
	SET_SELECTED_COUPON: "SET_SELECTED_COUPON",
	SET_COUPON_ABOVE: "SET_COUPON_ABOVE",
};
