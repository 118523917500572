import { createGlobalStyle } from "styled-components";

import { THEME } from "../../config";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Nunito', 'sans-serif';
  }

  html, body {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    background: ${THEME.main.general.primaryColor};
  }

	.Snackbar_snackbar-wrapper__ocbPJ{
		z-index: 1302;
	}

  html {
    font-size: 100%;

    &.no-scroll {
      overflow: hidden;
      margin: 0; 
      height: 100%;
    }
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    overflow-x: hidden;

    &.no-scroll {
      overflow: hidden;
    }	
  }

  button {
    border: 0 none;
  }

  #root {
    background: ${THEME.main.general.primaryColor};
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &.no-scroll {
      max-height: 100%;
      overflow: hidden;
    }

    &.iphone {
      padding-top: 2.5rem;

      #fixed-header {
        padding-top: 2.5rem;
        height: 5.625rem;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          height: 2.5rem;
          background: #fff;
          content: '';
          width: 100%;
        }
      }

      #store-categories {
        top: 5.625rem;
      }
    }
  }

  input,
  textarea,
  select,
  button {
    outline: none !important;
  }

  *:focus {
    outline: 0;
    outline: none;
  }

	.MuiInputLabel-outlined{
		z-index: 0 !important;
	}

  .MuiPaper-root * {   
    ::-webkit-scrollbar {
      width: 6px;
    }
    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${(props) =>
					(props.theme &&
						props.theme.main &&
						props.theme.main.general &&
						props.theme.main.general.textNinethColor) ||
					"#CCC"}; 
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${(props) =>
					(props.theme &&
						props.theme.main &&
						props.theme.main.general &&
						props.theme.main.general.textNinethColor) ||
					"#000"}; 
        -webkit-box-shadow: inset 0 0 6px ${(props) =>
					(props.theme &&
						props.theme.main &&
						props.theme.main.general &&
						props.theme.main.general.textNinethColor) ||
					"#CCC"}; 
    }
  }

	//O overflow foi ajustado pra exibir a imagem vazando
	.MuiDialog-root {
		.MuiDialog-paper {
			@media (min-width: 40rem) {
				margin: 3rem !important;
				overflow: unset;
			}
		}

		&.address-root-dialog {
			@media (min-width: 768px) {
				.MuiDialog-paper {
					max-width: 25rem
				}
    	}
		}

		&.my-coupons{
      .MuiDialog-paper {
        margin: 0 !important;
				overflow: auto !important;
      }
    }

    &.coupon-detail{
      .MuiDialog-paper {
        margin: 0 !important;
  
        @media (min-width: 40rem) {
          overflow: unset !important;
        }
      }
    }

    &.status-notification{
      .MuiDialog-paper {
        margin: 0 !important;
  
        @media (min-width: 40rem) {
          overflow: unset !important;
        }
      }
    }
    &.phone-detail{
      .MuiDialog-paper {
        margin: 0 !important;
  
        @media (min-width: 40rem) {
          overflow: unset !important;
        }
      }
    }
    
		&.login {
			/* .MuiDialog-paper {
				@media (min-width: 40rem) {
					overflow-y: auto !important;
				}
			} */
		}

		&.address {
			/* .MuiDialog-paper {
				@media (min-width: 40rem) {
					overflow-y: auto !important;
				}
			} */
		}

    &.stores-list{
      .MuiDialog-paper {
        margin: 0 !important;

				@media (min-width: 40rem) {
					overflow-y: auto !important;
				}
			}

      .MuiDialog-scrollPaper {
				justify-content: flex-start !important;
				align-items: flex-end !important;
			}
      
      .MuiDialog-paperScrollPaper {
				max-height: 35rem !important;
				min-height: 35rem !important;
        max-width: 100% !important;
				min-width: 100% !important;
			}

      @media(min-width: 40rem) {
				.MuiDialog-paper {
					margin: 1rem !important;
				}
		
				.MuiDialog-scrollPaper {
					justify-content: center !important;
					align-items: center !important;
				}
		
				.MuiDialog-paperScrollPaper {
					max-height: calc(100% - 64px) !important;
					min-height: unset !important;
					max-width: 25rem !important;
					min-width: 25rem !important;
				}
			}
    }

    &.change-password{
      .MuiDialog-paper {
        margin: 10px !important;

				@media (min-width: 40rem) {
					overflow-y: auto !important;
				}

        @media (max-width: 40rem) {
					overflow-y: hidden !important;
				}
			}

      .MuiDialog-scrollPaper {
				justify-content: flex-start !important;
				align-items: flex-start !important;
			}
      
      .MuiDialog-paperScrollPaper {
				max-height: 23rem !important;
				min-height: 23rem !important;
        max-width: 95% !important;
				min-width: 95% !important;
			}

      @media(min-width: 40rem) {
				.MuiDialog-paper {
					margin: 1rem !important;
				}
		
				.MuiDialog-scrollPaper {
					justify-content: center !important;
					align-items: center !important;
				}
		
				.MuiDialog-paperScrollPaper {
					max-height: calc(100% - 64px) !important;
					min-height: unset !important;
					max-width: 25rem !important;
					min-width: 25rem !important;
				}
			}
    }

		&.product-detail {
			.MuiBackdrop-root {
				background-color: rgba(0, 0, 0, .25);
			}
		}

    &.categories-detail {
      @media(min-width: 40rem) {
				.MuiDialog-paper {
					margin: 1rem !important;
          overflow-y: auto !important;
				}
		
				.MuiDialog-scrollPaper {
					justify-content: center !important;
					align-items: center !important;
				}
		
				.MuiDialog-paperScrollPaper {
					max-height: calc(100% - 64px) !important;
					min-height: auto !important;
					max-width: 25rem !important;
					min-width: 25rem !important;
				}
			}
    }

		&:not(.addresses-postal-code):not(.product-detail)
			:not(.anti-fraud):not(.coupon-detail):not(.phone-detail)
			:not(.status-notification):not(.stores-list):not(.categories-detail) {
			.MuiDialog-paper {
				margin: 0 !important;
			}
		
			.MuiDialog-scrollPaper {
				justify-content: flex-start !important;
				align-items: flex-start !important;
			}
		
			.MuiDialog-paperScrollPaper {
				max-height: 100% !important;
				min-height: 100% !important;
				max-width: 100% !important;
				min-width: 100% !important;
			}
		
			@media(min-width: 40rem) {
				.MuiDialog-paper {
					margin: 1rem !important;
				}
		
				.MuiDialog-scrollPaper {
					justify-content: center !important;
					align-items: center !important;
				}
		
				.MuiDialog-paperScrollPaper {
					max-height: calc(100% - 64px) !important;
					min-height: unset !important;
					max-width: 25rem !important;
					min-width: 25rem !important;
				}
			}
		}
	}
`;
export default GlobalStyle;
