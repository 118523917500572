export const calculatePackPrice = (items) => {
	const total =
		items &&
		items.length &&
		items.reduce((acc, item) => acc + calculateItemTotal(item), 0);

	return total || 0;
};
export const calculateItemTotal = (item) => {
	const { packPrice } = item;

	const total = item.quantity * (packPrice ? packPrice : 0);

	return total;
};
