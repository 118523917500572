import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import history from "../../history";
import { ReactComponent as IconArrowLeft } from "../assets/icons/icon_arrow_left.svg";
import { ReactComponent as IconClose } from "../assets/icons/icon_close.svg";
import { InputRounded } from "../components";

import { signUp, user } from "../../state";

import {
	setNotification,
	setUserCard,
	setCard,
	setLogin,
	setUserHistory,
	handleGTM,
	handlePixel,
} from "../../redux/actions/main";
import { isCPFValid } from "../../infra/utils";

import { useDeviceLayout, useStoreSettings } from "../hooks";

import { Phone } from "./";

import {
	Wrapper,
	Header,
	Title,
	Form,
	FormFieldName,
	FormFieldLastName,
	FormFieldEmail,
	FormFieldBirthDate,
	FormFieldCPF,
	FormFieldPassword,
	FormFieldRepeatPassword,
	RegisterButton,
	TermsOfServiceLabel,
	PhoneHeader,
	ErrorMessage,
	ErrorMessageContent,
	ErrorMessageItem,
} from "./styles";

function SignUpPage(props) {
	const {
		setSignUp,
		setAccessToken,
		postSignUp,
		postSignUpFacebook,
		postSignUpGoogle,
		signUp,
		setCard,
		setUserCard,
		setLogin,
		getSignUp,
		setNotification,
		postCode,
		setUserHistory,
		postPhone,
		close,
		userHistory,
		URLParameters,
		signIn,
		//requiredValidationPhone,
		setRequiredValidationPhone = () => { },
		handleGTM,
		method,
		products,
	} = props;

	const {
		/* birthDate,
		CPF,
		password,
		password2,
		phone,
		code,
		ddi,
		country, */
		confirmationType,
		messageErrors,
		resend,
	} = signUp;
	/* const { name, lastName, email } = signUp */

	const { back } = userHistory;

	const phoneReadyInitialValue = signIn?.subscriptionStatus === 2 && !signIn?.signUp;

	const isMobile = useDeviceLayout({
		isMobile: true,
	});

	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [ready, setReady] = useState(false);
	const [phoneReady, setPhoneReady] = useState(phoneReadyInitialValue);
	const [phoneStep, setPhoneStep] = useState(0);
	const [phoneHeaderTitle, setPhoneHeaderTitle] = useState("Cadastrar celular");
	const [time, setTime] = useState(30);
	const [signUpError, setSignUpError] = useState(null);
	const [values, setValues] = useState({ ...signUp });

	const {
		name,
		lastName,
		email,
		birthDate,
		CPF,
		password,
		password2,
		phone,
		code,
		ddi,
		country,
	} = values;

	const signUpErrorMessages = signUpError ? signUpError.split("\r\n") : "";
	const signUpErrorMessagesPrefix = signUpErrorMessages.length > 1 ? "- " : "";

	let timeout = null;

	const {
		url,
		title,
		//description
	} = useStoreSettings();

	useEffect(() => {
		document.querySelector("#root").style.background = "#fff";

		if (!loaded && !loading) {
			setLoading(false);
			setLoaded(true);
			setReady(true);

			const signUp = getSignUp();

			setSignUp({
				...signUp,
				ddi: 55,
				country: "brasil",
				confirmationType: "sms",
				messageErrors: 0,
				resend: false,
			});
		}

		return () => { };
	}, [loaded, loading, ready, phoneReady, setSignUp, signUp, getSignUp]);

	function handleInput(event) {
		const { name, value } = event.target;

		const newValue = {
			[name]: value,
		};

		if (name === "ddi") {
			const value = event.target.value.split("|");
			const country = value[0];
			const ddi = value[1];

			Object.assign(newValue, {
				country,
				ddi,
			});
		}

		setValues({
			...values,
			...newValue,
		});
	}

	function handleBirthDateKeyDown(event) {
		const { keyCode } = event;

		if (
			!(
				(keyCode >= 48 && keyCode <= 57) ||
				(keyCode >= 96 && keyCode <= 105) ||
				keyCode === 8 ||
				keyCode === 9 ||
				keyCode === 46 ||
				keyCode === 37 ||
				keyCode === 39
			)
		) {
			event.preventDefault();
		}

		if (
			keyCode === 191 &&
			birthDate &&
			(birthDate.indexOf("/") > -1 || birthDate.length !== 5)
		) {
			event.preventDefault();
		}

		if (
			birthDate &&
			(birthDate.length === 2 || birthDate.length === 5) &&
			keyCode !== 8 &&
			keyCode !== 9 &&
			keyCode !== 37 &&
			keyCode !== 39 &&
			keyCode !== 46
		) {
			handleInput({
				...event,
				target: {
					...event.target,
					name: "birthDate",
					value: `${birthDate}/`,
				},
			});
		}
	}

	function handleCPFKeyDown(event) {
		const { keyCode } = event;

		if (
			!(
				(keyCode >= 48 && keyCode <= 57) ||
				(keyCode >= 96 && keyCode <= 105) ||
				keyCode === 8 ||
				keyCode === 9 ||
				keyCode === 46
			)
		) {
			event.preventDefault();
		}

		if (
			CPF &&
			(CPF.length === 3 || CPF.length === 7) &&
			keyCode !== 8 &&
			keyCode !== 46
		) {
			handleInput({
				...event,
				target: {
					...event.target,
					name: "CPF",
					value: `${CPF}.`,
				},
			});
		}

		if (CPF && CPF.length === 11 && keyCode !== 8 && keyCode !== 46) {
			handleInput({
				...event,
				target: {
					...event.target,
					name: "CPF",
					value: `${CPF}-`,
				},
			});
		}
	}

	function handleStep(args) {
		const titles = {
			0: "Cadastrar celular",
			1: "Código de confirmação",
		};
		setPhoneHeaderTitle(titles[args]);
		setPhoneStep(args);
	}

	async function handleSubmit() {
		setSignUpError(null);

		if (!isCPFValid(values.CPF)) {
			setSignUpError("O CPF é inválido");
			return;
		}
		const dataSingUp = {
			event: "sign_up",
			method: method,
			step: "2",
		};
		handleGTM(dataSingUp);
		console.warn({ dataSingUp });

		setLoading(true);

		setSignUp({
			...signUp,
			...values,
			success: false,
		});

		if (signUp.facebookUserId) {
			await postSignUpFacebook();
		} else if (signUp.googleUserId) {
			await postSignUpGoogle();
		} else {
			await postSignUp();
		}

		const newSignUp = getSignUp();

		if (newSignUp && newSignUp.success) {
			const token = newSignUp.token && newSignUp.token.accessToken;

			setAccessToken(token);
			setPhoneReady(true);
			setLoading(false);
			setNotification(null);
			setSignUpError(null);
			setRequiredValidationPhone(true);

			return;
		}

		const notificationMessage =
			newSignUp.message || "Não foi possível fazer o cadastro!";

		setNotification({
			type: "warning",
			message: notificationMessage,
		});
		setSignUpError(notificationMessage);

		setLoading(false);
	}

	function postTime(args) {
		setTime(args);
	}

	function handleTime(startTime, time = 0, data = {}) {
		const signUp = getSignUp();

		if (!time && data) {
			setSignUp({
				...signUp,
				...data,
			});
		}

		if (data.clear) {
			postTime(30, 30);
			clearTimeout(timeout);

			return;
		}

		timeout = setTimeout(() => {
			postTime(Number(time - 1));

			if (time - 1 > 0) {
				handleTime(startTime, time - 1);
			}
		}, 1000);
	}

	async function handlePhoneSubmit() {
		setNotification(null);

		const dataSingUp = {
			event: "sign_up",
			method: method,
			step: "3",
		};
		handleGTM(dataSingUp);
		console.warn({ dataSingUp });

		setSignUp({
			...signUp,
			...values,
		});

		await postPhone([
			(errorMessage) => {
				const notificationMessage =
					errorMessage ||
					"Não foi possível enviar o código para seu telefone celular.";

				setNotification({
					type: "warning",
					message: notificationMessage,
				});

				setSignUpError(notificationMessage);
				handleStep(0);
			},
			() => {
				const newConfirmationType = messageErrors + 1 >= 4 ? "phone" : "sms";

				handleTime(30, 30, {
					messageErrors: messageErrors + 1,
					confirmationType: newConfirmationType,
					resend: true,
					clear: false,
				});
				setNotification(null);
				setSignUpError(null);
				handleStep(1);
			},
		]);
	}

	async function handleCodeSubmit() {
		setNotification(null);
		const dataSingUp = {
			event: "sign_up",
			method: method,
			step: "4",
		};
		handleGTM(dataSingUp);
		console.warn({ dataSingUp });

		setSignUp({
			...signUp,
			...values,
		});

		await postCode([
			{
				name: "address",
				type: "error",
				callback: (errorMessage) => {
					setUserHistory({
						next: "sign-up",
						back: "sign-up",
					});

					const notificationMessage =
						errorMessage ||
						"Não foi possível cadastrar seu endereço. Tente novamente.";
					setNotification({
						type: "warning",
						message: notificationMessage,
					});

					history.push(`/new-address${URLParameters}`);
				},
			},
			{
				name: "address",
				type: "success",
				callback: (b) => {
					console.warn("Address created");
				},
			},
			{
				name: "code",
				type: "error",
				callback: (errorMessage) => {
					const notificationMessage =
						errorMessage ||
						"Não foi possível validar o código para seu telefone celular.";
					setNotification({
						type: "warning",
						message: notificationMessage,
					});
					setSignUpError(notificationMessage);

					setPhoneReady(true);
					setRequiredValidationPhone(true);
				},
			},
			{
				name: "code",
				type: "success",
				callback: () => {
					//Pixel 1.0 - Evento de criação de conta
					const dataPixel = {
						eventName: `CompleteRegistration`,
						body: {
							content_name: 'Cadastro',
							currency: 'BRL',
							status: true,
							value: 1
						}
					}

					handlePixel(dataPixel);

					const signUp = getSignUp();

					const token = signUp.token && signUp.token.accessToken;

					const { email, password } = signUp;

					setCard(null);
					setUserCard(null);
					token && setAccessToken(token);
					setLogin({
						...signUp,
						email,
						password,
						success: true,
						subscriptionStatus: 1,
					});
					setPhoneReady(false);
					setNotification(null);
					setSignUpError(null);
					setSignUp(null);
					setRequiredValidationPhone(false);

					if (close && typeof close === "function") {
						close();
					}

					const { back } = userHistory;

					if (isMobile && products && products.length > 0) {
						history.push(`/cart${URLParameters}`);
						return;
					} else if (back && back !== "/") {
						history.push(`/${back}${URLParameters}`);
					} else {
						history.push(`/${URLParameters}`);
					}
				},
			},
		]);
	}

	return (
		<Wrapper>
			<Helmet>
				<title>{`${title} - Cadastro`}</title>

				<link
					rel="canonical"
					href={`${close && typeof close === "function"
						? `${url}/store`
						: `${url}/sign-up`
						}`}
				/>
			</Helmet>

			{!phoneReady && (
				<>
					{signUpError && (
						<ErrorMessage>
							<ErrorMessageContent>
								{signUpErrorMessages.map((error, index) => (
									<ErrorMessageItem key={index}>
										{signUpErrorMessagesPrefix}
										{error}
									</ErrorMessageItem>
								))}
							</ErrorMessageContent>
							<IconClose />
						</ErrorMessage>
					)}
					<Header>
						<IconArrowLeft
							onClick={() => {
								if (back && back !== "/" && back !== "sign-up") {
									history.push(`/${back}${URLParameters}`);
								} else {
									window.history.back();
								}
							}}
						/>
						<Title>Cadastrar nova conta</Title>
					</Header>
					<Form
						onSubmit={(event) => {
							event.preventDefault();
						}}
					>
						<FormFieldName>
							<InputRounded
								placeholder="Nome"
								name="name"
								value={name}
								onChange={handleInput}
							/>
						</FormFieldName>
						<FormFieldLastName>
							<InputRounded
								placeholder="Sobrenome"
								name="lastName"
								value={lastName}
								onChange={handleInput}
							/>
						</FormFieldLastName>
						<FormFieldEmail>
							<InputRounded
								placeholder="E-mail"
								name="email"
								value={email}
								onChange={handleInput}
							/>
						</FormFieldEmail>
						<FormFieldBirthDate>
							<InputRounded
								placeholder="Data de nascimento"
								maxLength={10}
								name="birthDate"
								value={birthDate}
								onChange={handleInput}
								onKeyDown={handleBirthDateKeyDown}
							/>
						</FormFieldBirthDate>
						<FormFieldCPF>
							<InputRounded
								placeholder="CPF"
								className="half"
								maxLength={14}
								name="CPF"
								value={CPF}
								onKeyDown={handleCPFKeyDown}
								onChange={handleInput}
							/>
						</FormFieldCPF>
						{signUp && !signUp.googleUserId && !signUp.facebookUserId ? (
							<>
								<FormFieldPassword>
									<InputRounded
										placeholder="Senha"
										type="password"
										name="password"
										value={password}
										onChange={handleInput}
									/>
								</FormFieldPassword>
								<FormFieldRepeatPassword>
									<InputRounded
										placeholder="Confirmar senha"
										type="password"
										name="password2"
										value={password2}
										onChange={handleInput}
									/>
								</FormFieldRepeatPassword>
							</>
						) : null}
						<RegisterButton
							onClick={() => {
								handleSubmit();
							}}
						>
							Cadastrar
						</RegisterButton>
						<TermsOfServiceLabel>
							Ao se cadastrar você concorda com os
							<a
								href="https://institucional.voceqpad.com.br/termoscondicoes.html"
								target={"_blank"}
								rel="noreferrer"
								aria-label="Termos de uso"
							>
								Termos de Uso
							</a>
						</TermsOfServiceLabel>
					</Form>
				</>
			)}

			{phoneReady && (
				<>
					{signUpError && (
						<ErrorMessage>
							<ErrorMessageContent>
								{signUpErrorMessages.map((error, index) => (
									<ErrorMessageItem key={index}>
										{signUpErrorMessagesPrefix}
										{error}
									</ErrorMessageItem>
								))}
							</ErrorMessageContent>
							<IconClose />
						</ErrorMessage>
					)}
					<PhoneHeader>
						<Title>{phoneHeaderTitle}</Title>
					</PhoneHeader>
					<Phone
						step={phoneStep}
						country={country || "brasil"}
						ddi={ddi || 55}
						phone={phone}
						code={code}
						resend={resend}
						confirmationType={confirmationType}
						messageErrors={messageErrors}
						time={time}
						handleTime={handleTime}
						handleInput={handleInput}
						handlePhoneSubmit={handlePhoneSubmit}
						handleCodeSubmit={handleCodeSubmit}
					/>
				</>
			)}
		</Wrapper>
	);
}

const mapStateToProps = (state) => {
	return {
		signUp: state.signUp.signUp || {},
		method: state.signUp.method || null,
		userHistory: state.main.userHistory || {},
		URLParameters: state.main.URLParameters || "",
		signIn: state.main.login || {},
		products: (state.main.cart && state.main.cart.products) || [],
	};
};

const Connected = connect(mapStateToProps, {
	postSignUp: signUp.postSignUp,
	postSignUpFacebook: signUp.postSignUpFacebook,
	postSignUpGoogle: signUp.postSignUpGoogle,
	postPhone: signUp.postSignUpPhone,
	setSignUp: signUp.setSignUp,
	getSignUp: signUp.getSignUp,
	postCode: signUp.postSignUpPhoneCode,
	setAccessToken: user.setAccessToken,
	setCard,
	setUserCard,
	setLogin,
	setNotification,
	setUserHistory,
	handleGTM,
})(SignUpPage);

export function SignUp(props) {
	return <Connected {...props} />;
}
