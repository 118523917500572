import React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Helmet } from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import { ReactComponent as IconGoogle } from '../assets/icons/icon_google_multicolor.svg'
import { ReactComponent as IconFacebook } from '../assets/icons/icon_facebook.svg'
import { ReactComponent as IconClose } from '../assets/icons/icon_close.svg'

import {
	FACEBOOK_APP_ID,
} from '../../config'

import {
	login,
	setNotification,
	postFacebookLogin,
	postGoogleLogin,
	setUserHistory,
	setCard,
	setSignIn,
	setUserCard,
	setLogin,
	setMethodAccess,
	handleGTM
} from '../../redux/actions/main'

import {
	loading,
	userAddress,
	signUp,
	user,
	addressActions
} from '../../state'

import history from '../../history'

import { pipe, GetContent } from '../../domain/helpers'

import { useDeviceLayout } from '../hooks'

import {
	CustomIcon,
	CustomLoading,
	InputRounded
} from '../components'

import {
	Wrapper,
	Form,
	FormField,
	StoreLogoImage,
	SubmitButton,
	SingUpButton,
	Divider,
	DividerLabel,
	GoogleButton,
	FacebookButton,
	TermsOfServiceLabel,
	ForgotPasswordLink,
	MobileHeaderWrapper,
	CloseButton,
	ErrorMessage
} from './styles'

import { Phone } from '../sign-up'
import {
	PhoneHeader,
	Title
} from '../sign-up/styles'
import { ForgotPassword, useForgotPasswordDialogStyles } from '../password'
import { Dialog, Slide } from '@material-ui/core'
import { ChangePassword } from './ChangePassword'
import { useGoogleLogin } from '@react-oauth/google'
import Axios from 'axios'

const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

function SignInPage(props) {
	const {
		setVisibleSignIn,
		login,
		address,
		loading,
		setLoading,
		setSignIn,
		setNotification,
		addUserAddress,
		fetchUserAddresses,
		setVisibleSignUp,
		setVisibleForgotPassword,
		postGoogleLogin,
		postFacebookLogin,
		theme,
		setSignUp,
		setLogin,
		close,
		signIn,
		getSignUp,
		postPhone,
		postCode,
		setAccessToken,
		setUserHistory,
		setUserCpf,
		setUserName,
		accessToken,
		userHistory,
		setMethodAccess,
		URLParameters,
		setNoAddressesList,
		setRequiredValidationPhone,
		postEmailCode,
		postPassword,
		postResendCode,
		setMethod,
		products,
	} = props

	const {
		phone,
		code,
		ddi,
		country,
		confirmationType,
		messageErrors,
		resend
	} = signIn

	const storeLogo = theme.logoImageSignIn

	const {
		url,
		title,
		description
	} = useStoreSettings()

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	const [email, setEmail] = useState(null)
	const [password, setPassword] = useState(null)
	const [message, setMessage] = useState(null)
	const [phoneReady, setPhoneReady] = useState(false)
	const [signInError, setSignInError] = useState(null)
	const [phoneHeaderTitle, setPhoneHeaderTitle] = useState('Cadastrar celular')
	const [phoneStep, setPhoneStep] = useState(0)
	const [time, setTime] = useState(30)
	const [loginForgotPassword, setLoginForgotPassword] = useState(false)
	const [loginChangePassword, setLoginChangePassword] = useState(false)
	const forgotPasswordDialogClasses = useForgotPasswordDialogStyles()

	let timeout = null

	useEffect(() => {
		setEmail('')
		setPassword('')
		setLoading({ item: 'auth', delete: true })

		return () => ({})
	}, [setLoading])

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: async tokenResponse => {
			try {
				const data = await Axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
					headers: {
						"Authorization": `Bearer ${tokenResponse.access_token}`
					}
				})
				handleSubmitGoogleLogin({ ...data.data, accessToken: tokenResponse.access_token });
			} catch (error) {
				const errorMessage = 'Erro ao realizar o login com o Google, por favor tente novamente';
				if (error) {
					setMessage(errorMessage)

					return
				}
			}
		}
	});

	function handleInput(event) {
		const {
			name,
			value
		} = event.target

		const newValue = {
			[name]: value
		}

		if (name === 'ddi') {
			const value = event.target.value.split('|')
			const country = value[0]
			const ddi = value[1]

			Object.assign(newValue, {
				country,
				ddi
			})
		}

		setSignIn({
			...signIn,
			...newValue
		})
	}

	function handleStep(args) {
		const titles = {
			0: 'Cadastrar celular',
			1: 'Código de confirmação'
		}
		setPhoneHeaderTitle(titles[args])
		setPhoneStep(args)
	}

	function postTime(args) {
		setTime(args)
	}

	function handleTime(startTime, time = 0, data = {}) {
		const signUp = getSignUp()

		if (!time && data) {
			setSignUp({
				...signUp,
				...data
			})
		}

		if (data.clear) {
			postTime(30, 30)
			clearTimeout(timeout)

			return
		}

		timeout = setTimeout(() => {
			postTime(Number(time - 1))

			if (time - 1 > 0) {
				handleTime(startTime, time - 1)
			}
		}, 1000)
	}

	async function handlePhoneSubmit() {
		setNotification(null)
		await postPhone([(errorMessage) => {
			const notificationMessage = errorMessage || 'Não foi possível enviar o código para seu telefone celular.'
			setNotification({
				type: 'warning',
				message: notificationMessage
			})
			setSignInError(notificationMessage)
			handleStep(0)
		}, () => {
			const newConfirmationType = messageErrors + 1 >= 4 ? 'phone' : 'sms'

			handleTime(30, 30, {
				messageErrors: messageErrors + 1,
				confirmationType: newConfirmationType,
				resend: true,
				clear: false
			})
			setNotification(null)
			setSignInError(null)
			handleStep(1)
		}])
	}

	async function handleCodeSubmit() {
		setNotification(null)
		await postCode([{
			name: 'address',
			type: 'error',
			callback: (errorMessage) => {
				setUserHistory({
					next: 'login',
					back: 'login'
				})

				const notificationMessage = errorMessage || 'Não foi possível cadastrar seu endereço. Tente novamente.'
				setNotification({
					type: 'warning',
					message: notificationMessage
				})

				history.push(`/new-address${URLParameters}`)
			}
		}, {
			name: 'address',
			type: 'success',
			callback: () => {
				console.warn('Address created')
			}
		}, {
			name: 'code',
			type: 'error',
			callback: (errorMessage) => {
				const notificationMessage = errorMessage || 'Não foi possível validar o código para seu telefone celular.'
				setNotification({
					type: 'warning',
					message: notificationMessage
				})
				setSignInError(notificationMessage)

				setPhoneReady(true)
				setRequiredValidationPhone(true)
				setPhoneStep(1)
			}
		}, {
			name: 'code',
			type: 'success',
			callback: () => {
				const token = accessToken

				const {
					email,
					password
				} = signIn

				setCard(null)
				setUserCard(null)
				token && setAccessToken(token)
				setLogin({
					...signIn,
					email,
					password,
					success: true,
					subscriptionStatus: 1
				})
				setPhoneReady(false)
				setRequiredValidationPhone(false)
				setNotification(null)
				setSignInError(null)

				if (close && typeof close === 'function') {
					close()
				} else {
					const {
						back
					} = userHistory

					if (isMobile && products && products.length > 0) {
						history.push(`/cart${URLParameters}`)
						return
					} else if (back && back !== '/') {
						history.push(`/${back}${URLParameters}`)
					} else {
						history.push(`/${URLParameters}`)
					}
				}
			}
		}])
	}

	const SubmitLogin = async () => {
		setLoading('auth')
		const result = await login({ email, password })

		if (result && result.success && result.accessToken && result.subscriptionStatus === 1) {
			const dataLogin = {
				'event': 'login',
				'method': 'Email', //email, facebook ou google
				'step': '1'
			}
			handleGTM(dataLogin)
			console.warn({ dataLogin })

			const token = result.accessToken

			setAccessToken(token)

			setLogin({
				...signIn,
				email,
				password,
				success: true,
				subscriptionStatus: 1
			})

			setMethodAccess('email')

			if (address && address.main) {
				await addUserAddress([() => {
					setNotification({
						type: 'warning',
						message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
					})

				}, () => {
					console.warn('Address created')
				}])

				setNoAddressesList(false)
			} else {
				setNoAddressesList(true)
			}

			setTimeout(() => fetchUserAddresses(), 500)

			if (isMobile) {
				setMessage(null)
				setLoading({ item: 'auth', delete: true })

				const {
					back
				} = userHistory

				if (back && back !== '/') {
					history.push(`/${back}${URLParameters}`)
				} else {
					history.push(`/${URLParameters}`)
				}

				return
			}

			setVisibleSignIn(false)
			setMessage(null)
			setLoading({ item: 'auth', delete: true })
			return
		} else if (!result.success && result.message && result.subscriptionStatus !== 1) {
			setMessage(result.message)
			setLoading('')
			return
		} else if (result.success && result.accessToken && result.subscriptionStatus === 2) {
			const token = result && result.accessToken

			setAccessToken(token)

			setLogin({
				...signIn,
				ddi: 55,
				country: 'brasil',
				confirmationType: 'sms',
				messageErrors: 0,
				resend: false
			})

			setPhoneReady(true)
			setRequiredValidationPhone(true)
			return
		} else if (result.success && result.subscriptionStatus === 4) {
			setLoginChangePassword(true)
			setLoading('')
			return
		} else {
			const notificationMessage = 'Não foi possível fazer o login!'
			setNotification({
				type: 'warning',
				message: notificationMessage
			})
			setSignInError(notificationMessage)

			setLoading(false)
		}
	}

	const handleSubmitFacebookLogin = async (form) => {
		//GTM 3.0 - Evento de login via facebook
		setMethod('Facebook')
		handleGTM({
			'event': 'sign_up',
			'method': 'Facebook',
			'step': '1'
		})
		setMessage('')
		const errorMessage = 'Erro ao realizar o login com o Facebook, por favor tente novamente'

		if (form && form.error) {
			setMessage(errorMessage)

			return
		}

		setMethodAccess('facebook')

		setLoading('auth')
		const result = await postFacebookLogin(form)

		handleSocialLoginResult(result, form, errorMessage, 'facebook')
	}

	const handleSubmitGoogleLogin = async (form) => {
		//GTM 3.0 - Evento de login via google
		setMethod('Google')
		handleGTM({
			'event': 'sign_up',
			'method': 'Google',
			'step': '1'
		})

		setMessage('')
		const errorMessage = 'Erro ao realizar o login com o Google, por favor tente novamente'

		if (form && form.error) {
			setMessage(errorMessage)
			return
		}

		setMethodAccess('gmail')

		setLoading('auth')
		const result = await postGoogleLogin(form);

		handleSocialLoginResult(result, form, errorMessage, 'google')
	}

	const handleCloseDialogForgotPassword = () => {
		setLoginForgotPassword(false)
	}

	const handleCloseDialogChangePassword = () => {
		setLoginChangePassword(false)
	}

	const handleSocialLoginResult = (result, form, errorMessage, vendor) => {
		if (result && result.success && result.user && result.user.signUpStatus === 1) {
			const {
				cpf,
				name
			} = result.user
			const token = result.user && result.user.token && result.user.token.accessToken

			setAccessToken(token)
			setLogin({
				...signIn,
				email: form.email || form.profileObj.email,
				password: '',
				success: true,
				subscriptionStatus: 1
			})

			setUserName(name)
			setUserCpf(cpf)

			if (close && typeof close === 'function') {
				close()
			} else {
				history.push(`/${URLParameters}`)
			}

			return
		}

		if (result && result.success && result.user && result.user.signUpStatus === 2) {
			const token = result.user && result.user.token && result.user.token.accessToken

			setAccessToken(token)

			setLogin({
				...signIn,
				ddi: 55,
				country: 'brasil',
				confirmationType: 'sms',
				messageErrors: 0,
				resend: false
			})

			setPhoneReady(true)
			setRequiredValidationPhone(true)
			return
		}

		if (result && result.user && result.user.signUp && form && form.email && vendor === 'google') {

			const dataLogin = {
				'event': 'login',
				'method': 'google', //email, facebook ou google
				'step': '1'
			}
			handleGTM(dataLogin)
			console.warn(dataLogin)

			setSignUp({
				name: form.given_name,
				lastName: form.family_name,
				email: form.email,
				image: form.picture,
				googleToken: form.accessToken,
				googleUserId: form.sub
			})

			if (setVisibleSignUp && typeof setVisibleSignUp === 'function') {
				setVisibleSignUp(true)
			} else {
				history.push(`/sign-up${URLParameters}`)
			}

			return
		}

		if (result && result.user && result.user.signUp && form && form.userID && vendor === 'facebook') {
			const dataLogin = {
				'event': 'login',
				'method': 'facebook', //email, facebook ou google
				'step': '1'
			}
			handleGTM(dataLogin)
			console.warn(dataLogin)

			const userNames = form.name.split(' ')

			setSignUp({
				name: userNames[0],
				lastName: userNames[userNames.length - 1],
				email: form.email,
				image: form.picture.data.url,
				facebookToken: form.accessToken,
				facebookUserId: form.userID
			})

			if (setVisibleSignUp && typeof setVisibleSignUp === 'function') {
				setVisibleSignUp(true)
			} else {

				history.push(`/sign-up${URLParameters}`)
			}

			return
		}
		setLoading({ item: 'auth', delete: true })

		setLoading('')

		result && setMessage(result.message || errorMessage)
	}

	return <Wrapper>
		<Helmet>
			<title>{`${title} - Login`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={`${close && typeof close === 'function' ? `${url}/store` : `${url}/login`}`} />
		</Helmet>
		<MobileHeaderWrapper>
			<CloseButton className='close' onClick={() => {
				history.push(`/${URLParameters}`)
				close()
			}}>
				<CustomIcon name='Times' />
			</CloseButton>
		</MobileHeaderWrapper>
		{!phoneReady ? <>
			<StoreLogoImage src={storeLogo} />

			{message &&
				<ErrorMessage>
					<span>{message}</span>
					<IconClose />
				</ErrorMessage>
			}

			<Form onSubmit={(event) => {
				event.preventDefault()
			}}>
				<FormField>
					<InputRounded
						value={email || ''}
						onChange={e => setEmail(e.target.value)}
						type="text"
						name="email"
						placeholder="Usuário"
					/>
				</FormField>
				<FormField>
					<InputRounded
						value={password || ''}
						onChange={e => setPassword(e.target.value)}
						type="password"
						name="pass"
						placeholder="Senha" />
				</FormField>
				<ForgotPasswordLink onClick={() => {
					setVisibleForgotPassword ? setVisibleForgotPassword() : setLoginForgotPassword(true)
				}}>Esqueci minha senha</ForgotPasswordLink>
				<SubmitButton onClick={() => {
					SubmitLogin()
				}}>
					{loading.includes('auth') ?
						<CustomLoading
							color={'#fff'}
							type={'spin'}
							id='default-loading'
							height={30}
							width={30} /> :
						'Entrar'}
				</SubmitButton>
				<SingUpButton onClick={() => {
					//GTM 3.0 - Evento de criação de conta
					setMethod('Email')
					const dataSingUp = {
						'event': 'sign_up',
						'method': 'Email',
						'step': '1'
					}

					if (setVisibleSignUp && typeof setVisibleSignUp === 'function') {
						setVisibleSignUp(true)
						handleGTM(dataSingUp)
						console.warn({ dataSingUp })
					} else {
						history.push(`/sign-up${URLParameters}`)
						handleGTM(dataSingUp)
						console.warn({ dataSingUp })
					}
				}}>Ainda não tenho conta</SingUpButton>
				<Divider>
					<DividerLabel>OU</DividerLabel>
				</Divider>
				{<GoogleButton
					type='button'
					onClick={handleGoogleLogin}
				>
					<IconGoogle />
					<label>entrar com <span>Google</span></label>
				</GoogleButton>}
				<div id="googleButton"></div>
				{<FacebookLogin
					appId={FACEBOOK_APP_ID}
					autoLoad={false}
					fields="name,email,picture"
					disableMobileRedirect={true}
					isMobile={isMobile}
					callback={handleSubmitFacebookLogin}
					render={({ onClick, isDisabled }) => (
						<FacebookButton
							onClick={onClick}
							disabled={isDisabled}
							type='button'
						>
							<IconFacebook />
							<label>entrar com <span>Facebook</span></label>
						</FacebookButton>
					)}
				/>}
				<TermsOfServiceLabel>
					Ao se cadastrar você concorda com os
					<a
						href='https://institucional.voceqpad.com.br/termoscondicoes.html'
						target={'_blank'}
						rel='noreferrer'
						aria-label='Termos de uso'
					>
						Termos de Uso
					</a>
				</TermsOfServiceLabel>
			</Form>
		</> : <>
			{signInError &&
				<ErrorMessage>
					<span>{signInError}</span>
					<IconClose />
				</ErrorMessage>
			}
			<PhoneHeader>
				<Title>{phoneHeaderTitle}</Title>
			</PhoneHeader>
			<Phone
				step={phoneStep}
				country={country || 'brasil'}
				ddi={ddi || 55}
				phone={phone}
				code={code}
				resend={resend}
				confirmationType={confirmationType}
				messageErrors={messageErrors}
				time={time}
				handleTime={handleTime}
				handleInput={handleInput}
				handlePhoneSubmit={handlePhoneSubmit}
				handleCodeSubmit={handleCodeSubmit}
			/>
		</>}

		<Dialog
			onClose={() => handleCloseDialogForgotPassword()}
			aria-labelledby='forgot-password-dialog'
			open={loginForgotPassword}
			maxWidth={'xs'}
			fullWidth={false}
			fullScreen={false}
			classes={{
				scrollPaper: forgotPasswordDialogClasses.scrollPaper,
				paper: forgotPasswordDialogClasses.paper
			}}
			TransitionComponent={Transition}
		>
			<ForgotPassword backStep={() => handleCloseDialogForgotPassword()} />
		</Dialog>

		<Dialog
			onClose={() => handleCloseDialogChangePassword()}
			aria-labelledby='forgot-password-dialog'
			className={isMobile ? 'change-password' : ''}
			PaperProps={{
				style: {
					maxWidth: '22rem',
					maxHeight: '25rem',
					borderRadius: '.75rem',
					top: isMobile ? '16rem' : ''
				}
			}}
			open={loginChangePassword}
			maxWidth={'xs'}
			fullWidth={false}
			fullScreen={false}
			TransitionComponent={Transition}
		>
			<ChangePassword
				email={email}
				postEmailCode={postEmailCode}
				postPassword={postPassword}
				close={handleCloseDialogChangePassword}
				setPassword={setPassword}
				SubmitLogin={SubmitLogin}
				postResendCode={postResendCode}
			/>
		</Dialog>


	</Wrapper>
}

SignInPage.propTypes = {
	loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]))
}

const mapStateToProps = (state) => {
	return {
		loading: state.loading.loading || [],
		address: (state.address.address) || {},
		signIn: (state.main.login) || {},
		userHistory: (state.main.userHistory) || {},
		accessToken: state.user.accessToken || '',
		URLParameters: (state.main.URLParameters) || '',
		changePassword: (state.user.changePassword) || false,
		products: (state.main.cart && state.main.cart.products) || [],
	}
}

const GetConnection = connect(mapStateToProps, {
	fetchUserAddresses: userAddress.fetch,
	addUserAddress: userAddress.add,
	setLoading: loading.setLoading,
	postCode: signUp.postSignUpPhoneCode,
	postEmailCode: signUp.postSignUpEmailCode,
	postPassword: signUp.postSignUpPassword,
	postResendCode: signUp.postSignUpResendCode,
	postSignUp: signUp.postSignUp,
	postPhone: signUp.postSignUpPhone,
	getSignUp: signUp.getSignUp,
	setSignUp: signUp.setSignUp,
	setUserCpf: user.setUserCpf,
	setUserName: user.setUserName,
	setAccessToken: user.setAccessToken,
	setNoAddressesList: addressActions.setNoAddressesList,
	setNotification,
	postGoogleLogin,
	postFacebookLogin,
	setSignIn,
	login,
	setUserHistory,
	setCard,
	setUserCard,
	setLogin,
	setMethodAccess,
	setMethod: signUp.setMethod
})

export const SignIn = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'signin' })
)(SignInPage))
